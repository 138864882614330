import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import { AiOutlineApple } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import particle1 from "assets/images/app/particle/09.png";
import particle2 from "assets/images/app/particle/10.png";
import particle3 from "assets/images/app/particle/11.png";
import particle4 from "assets/images/app/particle/13.png";

import GetAppSectionWrap from "./getAppSection.style";

const GetApp = (props) => {
  return (
    <GetAppSectionWrap>
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <img className="section__particle three" src={particle3} alt="img" />
      <img className="section__particle four" src={particle4} alt="img" />
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <div className="getapp-block">
              <h4>Get App Now!</h4>
              <h3>{props.title}</h3>
              <p>{props.detail}</p>
              <div className="btn-block">
                <Link to={props.link_web} className="getapp-btn">
                  <AiOutlineAppstoreAdd />
                  <p>
                    More Detail
                    <span>{props.title}</span>
                  </p>
                </Link>
                <a href={props.link_apple} className="getapp-btn apl-btn" target="_blank">
                  <AiOutlineApple />
                  <p>
                    Download
                    <span>App Store</span>
                  </p>
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="getapp-thumb">
              <img src={props.screen} alt="get the app" />
            </div>
          </Col>
        </Row>
      </Container>
    </GetAppSectionWrap>
  );
};

export default GetApp;
