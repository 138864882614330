import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";

import { IoIosAppstore } from "react-icons/io";

import BannerSectionWrapper from "./banner.style";

const BannerDefault = () => {
  return (
    <BannerSectionWrapper>
      <Particle />
      <Container fullWidthSM>
        <Row>
          <Col sm={7} md={6} lg={7}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Applications from the future</h4>
              <h1>
              Creative applications from <span>Cigam Tech</span>
              </h1>
            </SectionTitle>
            <p>
            Free yourself from the complicated life through the Cigam Tech applications.
            </p>
            <VintageBox right={true} vintageOne={true}>
              <Link to="/#firstApp" className="banner-btn one">
                <Button className="banner-btn one">
                  All Apps
                </Button>
              </Link>
              <a href="https://apps.apple.com/us/developer/huatao-jiang/id1439804976" target="_blank" className="banner-btn two">
                <Button className="banner-btn two">
                  App Store
                </Button>
              </a>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
