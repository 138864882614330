import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import { SlickSlider, SliderItem } from "reusecore/SlickSlider";

import particle1 from "assets/images/app/particle/09.png";
import particle2 from "assets/images/app/particle/10.png";
import particle3 from "assets/images/app/particle/11.png";
import particle4 from "assets/images/app/particle/13.png";

import AppScreenSectionWrapper from "./appScreenSection.style";

const AppScreens = (props) => {
  const thumbs = [props.img1, props.img2, props.img3, props.img4];
  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={thumbs[i]} alt="img" />
        </a>
      );
    },
    infinite: true,
    autoplay: true,
    dots: true,
    className: "app__screen__slider",
    dotsClass: "slick-dots slick-thumb",
    centerPadding: "100px",
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    infinite: true,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: "280px"
        }
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 3,
          centerPadding: "80px"
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "50px"
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px"
        }
      }
    ]
  };
  return (
    <AppScreenSectionWrapper id="appScreen">
      <div className="app-screen-one-block">
        <img className="section__particle one" src={particle1} alt="img" />
        <img className="section__particle two" src={particle2} alt="img" />
        <img className="section__particle four" src={particle4} alt="img" />
        <Container>
          <Row>
            <Col className="app-screen-content-block" xs={12} lg={6}>
              <div className="app__screen__conent">
                <SectionTitle
                  className="section-title"
                  leftAlign={true}
                  UniWidth="100%"
                >
                  <h4>App Screens</h4>
                  <h2>
                    <span>See screenshot </span> & engage in this app.
                  </h2>
                </SectionTitle>
                <Button>View on App Store</Button>
              </div>
            </Col>
            <Col className="app-screen-slider-block" xs={12} lg={6}>
              <SlickSlider {...settings}>
                <SliderItem key={"1"}>
                  <img src={props.img1} alt="img" />
                </SliderItem>
                <SliderItem key={"2"}>
                  <img src={props.img2} alt="img" />
                </SliderItem>
                <SliderItem key={"3"}>
                  <img src={props.img3} alt="img" />
                </SliderItem>
                <SliderItem key={"4"}>
                  <img src={props.img4} alt="img" />
                </SliderItem>
              </SlickSlider>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="app-screen-two-block">
        <Container>
          <Row>
            <Col className="app-screen-content-block" offsetMd={6} md={6}>
              <SectionTitle
                  className="section-title"
                  leftAlign={true}
                  UniWidth="100%"
                >
                  {" "}
                  <h4>App Screens</h4>
                  <h2>
                    Applications from the <span>Future</span>
                  </h2>
                </SectionTitle>
                <div className="btn-group">
                    <Link to="/#firstApp">
                      <Button className="btn-outline one">
                        View more
                      </Button>
                    </Link>
                  
                    <a href="https://apps.apple.com/us/developer/huatao-jiang/id1439804976" target="_blank">
                      <Button className="btn-outline two">
                        App Store
                      </Button>
                    </a>
                </div>
            </Col>
          </Row>
        </Container>
    </div>
    </AppScreenSectionWrapper>
  );
};

export default AppScreens;
