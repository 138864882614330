import React from "react";
import {ThemeProvider} from 'styled-components';

import Layout from "../components/layout" 
import SEO from "../components/seo" 

import Navigation from 'sections/Navigation'; 
import Banner from 'sections/Banner-default'; 
import AppScreens from 'sections/AppScreens';
import Getapp from 'sections/Getapp';
import Footer from 'sections/Footer-classic';

import { GlobalStyle } from 'sections/app.style';
import theme from 'theme/app/themeStyles';

import Screen_Todo from "assets/images/app/app-screen/todo.png";
import Screen_Pomo from "assets/images/app/app-screen/pomo.png";
import Screen_Water from "assets/images/app/app-screen/water.png";

import ScreenGroup_1 from "assets/images/app/app-screen/todo.png";
import ScreenGroup_2 from "assets/images/app/app-screen/pomo.png";
import ScreenGroup_3 from "assets/images/app/app-screen/water.png";
import ScreenGroup_4 from "assets/images/app/app-screen/todo.png";

const IndexPage = () => (
  <ThemeProvider theme={theme}> 
    <GlobalStyle />
     <Layout>
      <SEO title="Cigam Tech" />
      <Navigation pageName="Home" />
      <Banner />
      <AppScreens img1={ScreenGroup_1} img2={ScreenGroup_2} img3={ScreenGroup_3} img4={ScreenGroup_4} />
      <div id="firstApp">
        <Getapp 
          title="Next To Do" 
          detail="organize life, and do something creative everyday." 
          screen={Screen_Todo}
          link_web = "/NextToDo"
          link_apple = "https://apps.apple.com/us/app/next-to-do-to-do-list-task/id1552323822"
        />
      </div>
      <Getapp 
        title="Mini Pomo" 
        detail="The minimalist Pomodoro" 
        screen={Screen_Pomo}
        link_web = "/MiniPomo"
        link_apple = "https://apps.apple.com/us/app/mini-pomo-focus-timer/id1552213266"
      />
      <Getapp 
        title="Drink Reminder" 
        detail="Drink water on time and live a healthy life" 
        screen={Screen_Water}
        link_web = "/DrinkReminder"
        link_apple = "https://apps.apple.com/us/app/drink-reminder-water-tracker/id1550896263"
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
