import styled from 'styled-components'; 
import Slider from 'react-slick';
 

const SliderWrapper = styled(Slider)`

`
const SliderItemWrapper = styled.div``


export {SliderWrapper, SliderItemWrapper};